import { IDetailStyleLook, IModalStyleLook, INode, INodeMeta, IResponsive, IUrl } from '../types/stylelook-type'
import { isISetNode } from './utils_v2'
// throttle 함수
export const throttle = (func: (...args: any[]) => void, delay: number): ((...args: any[]) => void) => {
  let timeoutId: ReturnType<typeof setTimeout> | null = null
  return function (this: any, ...args: any[]) {
    if (!timeoutId) {
      timeoutId = setTimeout(() => {
        func.apply(this, args)
        timeoutId = null
      }, delay)
    }
  }
}
// debounce 함수
export const debounce = (func: (...args: any[]) => void, wait: number): ((...args: any[]) => void) => {
  let timeout: ReturnType<typeof setTimeout> | null = null
  return function executedFunction(this: any, ...args: any[]) {
    const later = () => {
      clearTimeout(timeout!)
      func.apply(this, args)
    }

    clearTimeout(timeout!)
    timeout = setTimeout(later, wait)
  }
}
// 두 배열이 같은지 확인
// 두 배열이 같은지 확인하는 함수
export const isArraysEqual = (arr1: string[], arr2: string[]): boolean => {
  // 먼저 길이가 같은지 확인
  if (arr1.length !== arr2.length) {
    return false
  }

  // arr1의 모든 요소가 arr2에 포함되는지 확인
  const isArr1InArr2 = arr1.every((element) => arr2.includes(element))

  // arr2의 모든 요소가 arr1에 포함되는지 확인
  const isArr2InArr1 = arr2.every((element) => arr1.includes(element))

  // 두 조건이 모두 참이면, 배열은 같은 값을 가짐
  return isArr1InArr2 && isArr2InArr1
}
// 배열에 없으면 추가
export const addArray = (setArr: string[], targetArr: string[]): string[] => {
  targetArr.forEach((item) => {
    if (!setArr.includes(item)) {
      setArr.push(item)
    }
  })
  return setArr
}
// 노드 타입 찾기 v1
export const findNodeType = (targetNode: Document | Element, findNode: IDetailStyleLook | IModalStyleLook) => {
  const goodsNoList: string[] = []
  if (findNode.node && findNode.node.length > 0) {
    findNode.node.forEach((node) => {
      if (isISetNode(node)) {
        if (node.findNode) {
          if (_isINodeType(node.findNode) === 'INodeMeta') {
            const goodsNo = _extractGoodsNoMeta(targetNode, node.findNode as INodeMeta)
            if (goodsNo) {
              goodsNoList.push(goodsNo)
            }
          } else if (_isINodeType(node.findNode) === 'IUrl') {
            const goodsNo = _extractGoodsNoUrl(node.findNode as IUrl)
            if (goodsNo) {
              goodsNoList.push(goodsNo)
            }
          } else if (_isINodeType(node.findNode) === 'INode') {
            const goodsNos = _extractGoodsNoNode(targetNode, node.findNode as INode)
            if (goodsNos) {
              goodsNos.forEach((goodsNo) => {
                if (!goodsNoList.includes(goodsNo)) {
                  goodsNoList.push(goodsNo)
                }
              })
            }
          }
        }
      }
    })
  }
  return goodsNoList
}
// 노드 배열 리스트 에서 찾기
const _isINodeType = (node: INodeMeta | IUrl | INode): string => {
  if ('property' in node && 'propertyValue' in node) {
    return 'INodeMeta'
  } else if ('targetUrl' in node && 'findType' in node && 'findValue' in node) {
    return 'IUrl'
  } else if ('findNode' in node && 'findValue' in node) {
    return 'INode'
  }
  return ''
}
// 자신의 도메인 주소 확인해서 주소 반환하는 함수
const _addDomainToUrl = (url: string): string => {
  try {
    const domain = window.location.origin
    const absoluteUrl = new URL(url, domain)
    return absoluteUrl.toString()
  } catch (e) {
    console.error('error:', e)
    return url // 에러가 발생하면 원래의 url을 반환합니다.
  }
}
// 공통 파라미터에서 찾기
const _getUrlSearchParam = (url: string, findValue: string): string | null => {
  const urls = new URL(_addDomainToUrl(url))
  return urls.searchParams.get(findValue)
}
// 공통 몇번째 url 파라미터에서 찾기
const _getUrlSearchParamCnt = (url: string, findValue: string): string | null => {
  try {
    const urls = new URL(_addDomainToUrl(url))
    const findValueAsNumber = parseInt(findValue, 10)
    const pathSegments = urls.pathname.split('/') // 경로 세그먼트를 분할합니다.
    return pathSegments[findValueAsNumber]
  } catch (e) {
    console.error('error:', e)
    return null
  }
}
const _getUrlSegment = (index: number) => {
  try {
    const url = window.location.pathname // 현재 URL의 경로를 가져옵니다.
    const urlSegments = url.split('/') // URL을 '/' 기준으로 분할합니다.
    return urlSegments[index] // index 번째 세그먼트를 반환합니다.
  } catch (e) {
    return null
  }
}
// url 에서 찾기
const _extractGoodsNoUrl = (node: IUrl): string | null => {
  // url에서 findValue에 해당하는 값 찾기
  if (node.findType === 'getParameter') {
    return _getUrlSearchParam(node.targetUrl, node.findValue)
  } else if (node.findType === 'parameterCnt') {
    return _getUrlSegment(parseInt(node.targetUrl, 10))
  }
  return null
}
// 메타 태그에서 찾기
const _extractGoodsNoMeta = (targetNode: Document | Element, node: INodeMeta): string | null => {
  // meta[property="**"]에 해당하는 요소 찾기
  const metaElement = targetNode.querySelector(`meta[property="${node.property}"]`)
  if (!metaElement) return null // 요소가 없으면 null 반환
  // content 속성 값 가져오기
  const contentValue = metaElement.getAttribute(node.propertyValue)
  if (!contentValue) return null // content 속성 값이 없으면 null 반환
  // content 속성 값에서 해당하는 값 추출하기
  if (node.findType === 'getParameter') {
    return _getUrlSearchParam(contentValue, node.findValue)
  } else if (node.findType === 'getValue') {
    return contentValue
  }
  return null
}
// a link에 있는 href에서 찾기 파라미터로 찾기
const _getHrefUrlListParameter = (aTags: NodeListOf<Element>, value: string): string[] => {
  const findValues: string[] = []
  aTags.forEach((a: Element) => {
    const getUrl = a.getAttribute('href')
    if (!getUrl) return
    const findValue = _getUrlSearchParam(getUrl, value)
    if (findValue) {
      findValues.push(findValue)
    }
  })
  return findValues
}
// 주소 파라미터에 몇번째 값 가져오기
const _getHrefUrlParameterCntValue = (aTags: NodeListOf<Element>, value: string): string[] => {
  const findValues: string[] = []
  aTags.forEach((a: Element) => {
    const getUrl = a.getAttribute('href')
    if (!getUrl) return
    const findValue = _getUrlSearchParamCnt(getUrl, value)
    if (findValue) {
      findValues.push(findValue)
    }
  })
  return findValues
}
// Element 배열에서 attr 안에 정규식으로 값 가져오기
const _getRegularValue = (aTags: NodeListOf<Element>, value: string, reg?: string): string[] => {
  const findValues: string[] = []
  aTags.forEach((a: Element) => {
    const findValue = (a as any)[value]
    if (findValue) {
      if (reg) {
        if (findValue.includes(reg)) {
          const result = findValue.replace(reg, '')
          findValues.push(result)
        }
      } else {
        findValues.push(findValue)
      }
    }
  })
  return findValues
}
// Element 배열에서 attr 값 가져오기
const _getAttrValue = (elements: NodeListOf<Element>, value: string): string[] => {
  const findValues: string[] = []
  elements.forEach((a: Element) => {
    if (a.getAttribute(value)) {
      findValues.push(a.getAttribute(value)!)
    }
  })
  return findValues
}
const _extractGoodsNoNode = (targetNode: Document | Element, node: INode): string[] | null => {
  const aTags = targetNode.querySelectorAll(node.findNode)
  const searchType = node.findType ?? 'getParameter'
  if (searchType === 'getParameter') {
    // href url query string 에서 node.findValue 에 해당하는 값을 추출
    return _getHrefUrlListParameter(aTags, node.findValue)
  } else if (searchType === 'parameterCnt') {
    // href url 파라미터에서 몇번째 값 찾기 0 부터 시작
    return _getHrefUrlParameterCntValue(aTags, node.findValue)
  } else if (searchType === 'getRegular') {
    // 정규식으로 값을 찾기 node.findValue 에 해당하는 attr의 값을 찾아 node.findValueOption 부분을 제거한 값을 반환
    return _getRegularValue(aTags, node.findValue, node.findValueOption)
  } else if (searchType === 'getAttr') {
    // 해당하는 node.findValue 에 해당하는 attr 값 그대로 출력
    return _getAttrValue(aTags, node.findValue)
  }
  return null
}
// element 화면 변동 시에 이벤트 추가 미구현 구현중...
export const setObserverList = (ele: HTMLDivElement, response: IResponsive[]) => {
  for (let i = 0; i < response.length; i++) {
    // 반응형 설정하기
    // 애니메이션 때문에 있는 넓이, 높이 변수
    var animWidth = '0px'
    var animHeight = '0px'

    var mediaQuery
    if (response[i].maxResponseSize && response[i].minEqualsResponseSize) {
      mediaQuery = window.matchMedia(
        `(min-width: ${response[i].minEqualsResponseSize}px) and (max-width: ${response[i].maxResponseSize}px)`,
      )
    } else if (response[i].maxResponseSize) {
      mediaQuery = window.matchMedia(`(max-width: ${response[i].maxResponseSize}px)`)
    } else if (response[i].minEqualsResponseSize) {
      mediaQuery = window.matchMedia(`(min-width: ${response[i].minEqualsResponseSize}px)`)
    } else {
      mediaQuery = null
    }
    // 반응형 코드 있을때
    if (mediaQuery) {
      // 반응형 이벤트 주입
      mediaQuery.addListener(function (e) {
        if (e.matches) {
          ele.style.cssText += response[i].responseCss
        }
      })
      // 주어진 조건에 따라서 넓이 높이 셋팅
      if (mediaQuery.matches) {
        let styles = (response[i].responseCss ?? '').split(';').reduce((acc: { [key: string]: string }, style) => {
          let [property, value] = style.split(':')
          if (property.trim() === 'width' || property.trim() === 'height') {
            acc[property.trim()] = value.trim()
          }
          return acc
        }, {})

        let width = styles.width
        let height = styles.height
        if (response[i].showAnimation && response[i].showAnimation?.animationName) {
          if (response[i].showAnimation?.animationName === 'slideIn') {
            animWidth = width
          }
        }
      }
    }
  }
}
