import {
  defaultModalHeight,
  defaultModalWidth,
  detailIframeId,
  dialogCss,
  dialogId,
  dialogIframeCss,
  dialogIframeId,
  dialogInnerCss,
  dialogInnerId,
  dialogInnerInDivCss,
  dialogInnerInDivId,
  host,
  thumArrCss,
  thumArrText,
  thumClssName,
  thumImageArrCss,
  thumImageArrPath,
  thumImageCss,
  thumImageHoverPath,
  thumImagePath,
  thumInnerClassName,
  thumInnerCss,
  thumOuterCss,
  thumTextHoverCss,
  thumTextLabel,
} from '../constants/constants'
import { IDialogStyleLook, IThumContainer, IThumContainerV2, ThumClickEventType } from '../types/stylelook-type'
import { closeDialogModal, isIThumContainer, isIThumContainerV2 } from './utils_v2'

// 다이얼로그 다시 그리기
export const reCallDialog = (dialog: IDialogStyleLook) => {
  if (dialog.dialogIframe) {
    dialog.dialogIframe.src = dialog.dialogIframe.src
  }
}
// 썸네일 그리기
export const drawThumContainer = (
  thum: IThumContainer | IThumContainerV2 | undefined,
  goodsNos: string,
  type: string,
  dialog: IDialogStyleLook,
  url: string,
  thumClickEventType?: ThumClickEventType,
) => {
  // 내부 컨테이너 셋팅 >> 디폴트 값 셋팅
  const thumInnerContainer = document.createElement('div')
  if (!thum) {
    return thumInnerContainer
  }
  thumInnerContainer.classList.add(thumInnerClassName)
  thumInnerContainer.style.cssText = thumInnerCss
  // hover 설정
  const thumInnerHoverContainer = document.createElement('div')
  thumInnerHoverContainer.classList.add(thumInnerClassName)
  thumInnerHoverContainer.style.cssText = thumInnerCss

  // 외곽 썸네일 그리기 >> 디폴트 값 셋팅
  const thumOuterContainer = document.createElement('div')
  thumOuterContainer.classList.add(thumClssName)
  thumOuterContainer.style.cssText = thumOuterCss
  // 외곽 class 추가
  if (thum.outerClassList) {
    thumOuterContainer.classList.add(...thum.outerClassList)
  }
  // 외곽 css 추가
  if (thum.outerCss) {
    thumOuterContainer.style.cssText += thum.outerCss
  }
  // 내부 class 추가
  if (thum.innerClassList) {
    thumInnerContainer.classList.add(...thum.innerClassList)
    thumInnerHoverContainer.classList.add(...thum.innerClassList)
  }
  // 내부 css 추가
  if (thum.innerCss) {
    thumInnerContainer.style.cssText += thum.innerCss
    thumInnerHoverContainer.style.cssText += thum.innerCss
  }
  // 비교 구문
  if (isIThumContainer(thum)) {
    // v1
    if (thum.innerContent) {
      if (thum.drawType === 'label') {
        thumInnerContainer.innerText = thum.innerContent
      } else if (thum.drawType === 'image') {
        const thumImage = document.createElement('img')
        thumImage.src = thum.innerContent
        thumImage.style.cssText = thumImageCss
        thumInnerContainer.appendChild(thumImage)
      }
    } else if (thum.drawType === 'arr') {
      const arrWrapDiv = document.createElement('div')
      arrWrapDiv.style.cssText = thumArrCss
      if (thum.arrDrawContents) {
        arrWrapDiv.style.cssText += thum.arrDrawContents.outerCss
        if (thum.arrDrawContents.arrContents) {
          // 값이 있다면 커스텀
          for (let i = 0; i < thum.arrDrawContents.arrContents.length; i++) {
            if (thum.arrDrawContents.arrContents[i].contentsKind === 'imagePath') {
              const thumImage = document.createElement('img')
              thumImage.src = thum.arrDrawContents.arrContents[i].innerContent ?? thumImageArrPath
              thumImage.style.cssText = thum.arrDrawContents.arrContents[i].innerCssContent ?? thumImageArrCss
              arrWrapDiv.appendChild(thumImage)
            } else if (thum.arrDrawContents.arrContents[i].contentsKind === 'text') {
              const iconlabelContainer = document.createElement('div')
              iconlabelContainer.style.cssText = thum.arrDrawContents.arrContents[i].innerCssContent ?? thumArrText
              iconlabelContainer.innerText = thum.arrDrawContents.arrContents[i].innerContent ?? thumTextLabel
              arrWrapDiv.appendChild(iconlabelContainer)
            }
            thumInnerContainer.appendChild(arrWrapDiv)
          }
        } else {
          // 값이 없으면 디폴트
          const thumImage = document.createElement('img')
          thumImage.src = thumImageArrPath
          thumImage.style.cssText = thumImageArrCss
          arrWrapDiv.appendChild(thumImage)
          const iconlabelContainer = document.createElement('div')
          iconlabelContainer.style.cssText = thumArrText
          iconlabelContainer.innerText = thumTextLabel
          arrWrapDiv.appendChild(iconlabelContainer)
          thumInnerContainer.appendChild(arrWrapDiv)
        }
      }
    } else if (thum) {
      // 타입별 디폴트 값 셋팅
      if (thum.drawType === 'label') {
        thumInnerContainer.innerText = thumTextLabel
      } else if (thum.drawType === 'image') {
        const thumImage = document.createElement('img')
        thumImage.src = thumImagePath
        thumImage.style.cssText = thumImageCss
        thumInnerContainer.appendChild(thumImage)
      }
    }
    if (thum.drawType === 'label' && thum.hoverEvent !== false) {
      thumOuterContainer.style.cssText += thumTextHoverCss
    }
    // hover event
    if (thum.hoverEvent !== false) {
      if (thum.drawType === 'image') {
        const selectImg = thumInnerContainer.querySelector('img')
        if (selectImg) {
          thumOuterContainer.addEventListener('mouseover', function () {
            if (thum.hoverInnerContent) {
              selectImg.src = thum.hoverInnerContent
            } else {
              selectImg.src = thumImageHoverPath
            }
          })
          thumOuterContainer.addEventListener('mouseout', function () {
            if (thum.innerContent) {
              selectImg.src = thum.innerContent
            } else {
              selectImg.src = thumImagePath
            }
          })
        }
      } else if (thum.drawType === 'label') {
        thumOuterContainer.addEventListener('mouseover', function () {
          thumOuterContainer.style.cssText += `min-width: ${thumOuterContainer.offsetWidth}px;`
          if (thum.hoverInnerContent) {
            thumInnerContainer.innerText = thum.hoverInnerContent
          } else {
            thumInnerContainer.innerText = thumTextLabel
          }
        })
        thumOuterContainer.addEventListener('mouseout', function () {
          if (thum.innerContent) {
            thumInnerContainer.innerText = thum.innerContent
          } else {
            thumInnerContainer.innerText = thumTextLabel
          }
        })
      }
    }
  } else if (isIThumContainerV2(thum)) {
    // v2
    if (thum.drawType === 'arr') {
      let hoverEvent = false
      const arrWrapDiv = document.createElement('div')
      // hover
      const arrWrapDivHover = document.createElement('div')
      arrWrapDiv.style.cssText = thumArrCss
      arrWrapDivHover.style.cssText = thumArrCss
      if (thum.arrDrawContents) {
        arrWrapDiv.style.cssText += thum.arrDrawContents.outerCss
        arrWrapDivHover.style.cssText += thum.arrDrawContents.outerCss
        if (thum.arrDrawContents.arrContents) {
          for (let i = 0; i < thum.arrDrawContents.arrContents.length; i++) {
            if (thum.arrDrawContents.arrContents[i].contentsKind === 'imagePath') {
              const thumImage = document.createElement('img')
              thumImage.src = thum.arrDrawContents.arrContents[i].innerContent ?? thumImageArrPath
              thumImage.style.cssText = thum.arrDrawContents.arrContents[i].innerCssContent ?? thumImageArrCss
              arrWrapDiv.appendChild(thumImage)
              if (thum.arrDrawContents.arrContents[i].hoverInnerContent) {
                // hover 액션이 있다면 해당 값 셋팅
                const thumImageHover = document.createElement('img')
                thumImageHover.src = thum.arrDrawContents.arrContents[i].hoverInnerContent ?? thumImage.src
                thumImageHover.style.cssText = thum.arrDrawContents.arrContents[i].innerCssContent ?? thumImageArrCss
                arrWrapDivHover.appendChild(thumImageHover)
              } else {
                // hover 디폴트는 기본값
                const thumImageClone = thumImage.cloneNode(true)
                arrWrapDivHover.appendChild(thumImageClone)
              }
            } else if (thum.arrDrawContents.arrContents[i].contentsKind === 'text') {
              const iconlabelContainer = document.createElement('div')
              iconlabelContainer.style.cssText = thum.arrDrawContents.arrContents[i].innerCssContent ?? thumArrText
              iconlabelContainer.innerText = thum.arrDrawContents.arrContents[i].innerContent ?? thumTextLabel
              arrWrapDiv.appendChild(iconlabelContainer)
              if (thum.arrDrawContents.arrContents[i].hoverInnerContent) {
                // hover 액션이 있다면 해당 값 셋팅
                const iconlabelContainerHover = document.createElement('div')
                iconlabelContainerHover.style.cssText =
                  thum.arrDrawContents.arrContents[i].innerCssContent ?? thumArrText
                iconlabelContainerHover.innerText =
                  thum.arrDrawContents.arrContents[i].hoverInnerContent ?? iconlabelContainer.innerText
                arrWrapDivHover.appendChild(iconlabelContainerHover)
              } else {
                // hover 디폴트는 기본값
                const thumImageClone = iconlabelContainer.cloneNode(true)
                arrWrapDivHover.appendChild(thumImageClone)
              }
            } else if (thum.arrDrawContents.arrContents[i].contentsKind === 'empty') {
              // 빈 태그 삽입
              const emptyContainer = document.createElement('div')
              emptyContainer.style.cssText = thum.arrDrawContents.arrContents[i].innerCssContent ?? ''
              arrWrapDiv.appendChild(emptyContainer)
              if (thum.arrDrawContents.arrContents[i].hoverInnerContent) {
                // hover 액션이 있다면 해당 값 셋팅
                const emptyContainerHover = document.createElement('div')
                emptyContainerHover.style.cssText = thum.arrDrawContents.arrContents[i].innerCssContent ?? ''
                arrWrapDivHover.appendChild(emptyContainerHover)
              } else {
                // hover 디폴트는 기본값
                const emptyContainerClone = emptyContainer.cloneNode(true)
                arrWrapDivHover.appendChild(emptyContainerClone)
              }
            }
            // 호버이벤트 구분자
            if (thum.arrDrawContents.arrContents[i].hoverInnerContent) {
              if (!hoverEvent) {
                hoverEvent = true
              }
            }
            thumInnerHoverContainer.appendChild(arrWrapDivHover)
            thumInnerContainer.appendChild(arrWrapDiv)
            if (hoverEvent) {
              // 호버 이벤트가 참이라면
              thumOuterContainer.addEventListener('mouseover', function () {
                if (thumOuterContainer.lastChild) {
                  thumOuterContainer.replaceChild(thumInnerHoverContainer, thumOuterContainer.lastChild)
                }
              })
              thumOuterContainer.addEventListener('mouseout', function () {
                if (thumOuterContainer.lastChild) {
                  thumOuterContainer.replaceChild(thumInnerContainer, thumOuterContainer.lastChild)
                }
              })
            }
          }
        } else {
          const thumImage = document.createElement('img')
          thumImage.src = thumImageArrPath
          thumImage.style.cssText = thumImageArrCss
          arrWrapDiv.appendChild(thumImage)
          const iconlabelContainer = document.createElement('div')
          iconlabelContainer.style.cssText = thumArrText
          iconlabelContainer.innerText = thumTextLabel
          arrWrapDiv.appendChild(iconlabelContainer)
          thumInnerContainer.appendChild(arrWrapDiv)
        }
      }
    }
  }
  thumOuterContainer.appendChild(thumInnerContainer)
  thumOuterContainer.onclick = (event) => {
    event.preventDefault()
    if (!thumClickEventType || thumClickEventType === 'modal') {
      _openDialog(goodsNos, type, dialog, url)
    } else if (thumClickEventType === 'scrollIntoView') {
      const element = document.getElementById(detailIframeId)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    } else {
      _openDialog(goodsNos, type, dialog, url)
    }
  }
  return thumOuterContainer
}

// 해상도 체크
const _getViewEnv = (dialog: IDialogStyleLook) => {
  var width = window.innerWidth || document.body.clientWidth
  const dialogWidth = dialog.dialogWidth ?? defaultModalWidth
  const iframeWidth = parseInt(dialogWidth.replace('px', ''), 10)
  if (width < iframeWidth) {
    return 'm'
  } else {
    return 'd'
  }
}
// 모달 열기
const _openDialog = (num: string, type: string, dialog: IDialogStyleLook, url: string) => {
  var dialogContainer = _drawDialog(num, type, dialog, url)
  document.body.prepend(dialogContainer)
  document.body.style.overflow = 'hidden'
}

// 모달 관련 UI 그리기
const _drawDialog = (num: string, type: string, dialog: IDialogStyleLook, url: string) => {
  // 애니메이션 때문에 있는 넓이, 높이 변수
  var animWidth = '0px'
  var animHeight = '0px'

  //
  var dialogContainer = document.createElement('div')
  dialogContainer.id = dialogId
  dialogContainer.style.cssText = dialogCss
  if (dialog.thumContainer?.outerCss) {
    dialogContainer.style.cssText += dialog.thumContainer.outerCss
  }
  var dialogInnerContainer = document.createElement('div')
  dialogInnerContainer.id = dialogInnerId
  dialogInnerContainer.style.cssText = dialogInnerCss
  if (dialog.thumContainer?.innerCss) {
    dialogInnerContainer.style.cssText += dialog.thumContainer.innerCss
  }
  dialogContainer.appendChild(dialogInnerContainer)
  var dialogDiv = document.createElement('div')
  dialogDiv.id = dialogInnerInDivId
  dialogDiv.style.cssText = dialogInnerInDivCss
  dialogInnerContainer.appendChild(dialogDiv)
  // 넓이 높이 초기값
  var _width = dialog.dialogWidth ?? defaultModalWidth
  var _height = dialog.dialogHeight ?? defaultModalHeight
  if (dialog.response && dialog.response.length > 0) {
    // 반응형 코드
    for (let i = 0; i < dialog.response.length; i++) {
      // 반응형 설정하기
      var mediaQuery
      if (dialog.response[i].maxResponseSize && dialog.response[i].minEqualsResponseSize) {
        mediaQuery = window.matchMedia(
          `(min-width: ${dialog.response[i].minEqualsResponseSize}px) and (max-width: ${dialog.response[i].maxResponseSize}px)`,
        )
      } else if (dialog.response[i].maxResponseSize) {
        mediaQuery = window.matchMedia(`(max-width: ${dialog.response[i].maxResponseSize}px)`)
      } else if (dialog.response[i].minEqualsResponseSize) {
        mediaQuery = window.matchMedia(`(min-width: ${dialog.response[i].minEqualsResponseSize}px)`)
      } else {
        mediaQuery = null
      }
      // 반응형 코드 있을때
      if (mediaQuery) {
        // 반응형 이벤트 주입
        mediaQuery.addListener(function (e) {
          if (e.matches) {
            var modalDialog = document.getElementById(dialogInnerInDivId)
            var dialogInner = document.getElementById(dialogInnerId)
            if (modalDialog && dialogInner && dialog.response) {
              modalDialog.style.width = dialog.response[i].widthCssString ?? defaultModalWidth
              modalDialog.style.height = dialog.response[i].heightCssString ?? defaultModalHeight
              modalDialog.style.cssText += dialog.response[i].responseCss ?? ''
              dialogInner.style.alignItems = dialog.response[i].showAnimation?.animShowAlignItems ?? 'center'
            }
          }
        })
        // 주어진 조건에 따라서 넓이 높이 셋팅
        if (mediaQuery.matches) {
          if (dialog.response[i].widthCssString) {
            _width = dialog.response[i].widthCssString ?? defaultModalWidth
          }
          if (dialog.response[i].heightCssString) {
            _height = dialog.response[i].heightCssString ?? defaultModalHeight
          }
          if (dialog.response[i].responseCss) {
            dialogDiv.style.cssText += dialog.response[i].responseCss
          }
          if (dialogInnerContainer) {
            dialogInnerContainer.style.alignItems = dialog.response[i].showAnimation?.animShowAlignItems ?? 'center'
          }
          if (dialog.response[i].showAnimation && dialog.response[i].showAnimation?.animationName) {
            if (dialog.response[i].showAnimation?.animationName === 'slideIn') {
              animWidth = _width
            }
          }
        }
      }
    }
  } else {
    // 반응형 코드 없는거
    // 모달열때 넓이 반응형에 따른 넓이 높이 계산
    if (_getViewEnv(dialog) === 'm') {
      _width = '100%'
      _height = '100%'
    }
  }
  // anim
  dialogDiv.animate(
    [
      { width: animWidth, height: animHeight },
      {
        width: _width,
        height: _height,
      },
    ],
    {
      duration: 300,
    },
  ).onfinish = function () {
    dialogDiv.style.width = _width
    dialogDiv.style.height = _height
    var styleLookIframe = document.createElement('iframe')
    styleLookIframe.setAttribute('frameborder', '0')
    styleLookIframe.id = dialogIframeId
    styleLookIframe.style.cssText = dialogIframeCss
    styleLookIframe.src = `${host}/${url}/modal/${num}?source=${type ?? '00'}`
    dialog.dialogIframe = styleLookIframe
    dialogDiv.append(styleLookIframe)
  }

  dialogContainer.onclick = function (event) {
    event.preventDefault()
    closeDialogModal()
  }
  return dialogContainer
}
