import {
  defaultMaxResponseSize,
  defaultMinResponseSize,
  host,
  mainContainerCss,
  mainContainerId,
  mainContainerPosition,
  mainIframeId,
} from '../constants/constants'
import { IMainStyleLook, renderPositionType } from '../types/stylelook-type'
import { throttle } from './utils'

// 메인 뷰 다시 그리기
export const reCallMain = (main: IMainStyleLook) => {
  if (main.mainIframe) {
    main.mainIframe.src = main.mainIframe.src.replace('?api=no', '')
  }
}
// 메인 뷰 셋팅 하기
export const setMainView = (main: IMainStyleLook, url: string) => {
  if (main.render) {
    if (!main.mainIframe) {
      // iframe 이 없을 경우 생성
      main.mainIframe = _drawMainIframe(url)
    } else {
      main.mainIframe.src = `${host}/${url}/main?api=no`
    }
    let index = 0
    for (var render of main.render) {
      var element = document.getElementById(`${mainContainerId}${index}`)
      if (!element && render.renderHref !== undefined && (render.renderHref ?? []).length > 0) {
        // 랜더할 url이 있을 경우
        const currentUrl = window.location.href
        for (var renderHref of render.renderHref) {
          if (currentUrl.includes(renderHref)) {
            const nodeList = document.querySelectorAll(render.renderContainer)
            if (nodeList && nodeList.length > 0) {
              for (var node of nodeList) {
                _drawMainContainer(node, main, index, render.renderPosition ?? mainContainerPosition)
              }
            }
          }
        }
      } else if (!element) {
        // 모두 노출
        var element = document.getElementById(`${mainContainerId}${index}`)
        if (!element) {
          const nodeList = document.querySelectorAll(render.renderContainer)
          if (nodeList && nodeList.length > 0) {
            for (var node of nodeList) {
              _drawMainContainer(node, main, index, render.renderPosition ?? mainContainerPosition)
            }
          }
        }
      }
      if (render.minEqualsResponseSize || render.maxResponseSize) {
        var width = window.innerWidth || document.body.clientWidth
        if (
          (render.minEqualsResponseSize ?? defaultMinResponseSize) <= width ||
          (render.maxResponseSize ?? defaultMaxResponseSize) > width
        ) {
          main = _addEventListener(main)
        }
        index++
      }
    }
    _setResponsive(main)
  }
  return main
}
// 메인 컨테이너 그리기
const _drawMainContainer = (node: Element, main: IMainStyleLook, index: number, position: renderPositionType) => {
  const mainContainer = document.createElement('div')
  mainContainer.id = `${mainContainerId}${index}`
  if (main.mainElementCustom) {
    mainContainer.classList.add(...(main.mainElementCustom.classList ?? []))
    mainContainer.style.cssText = main.mainElementCustom.cssList ?? ''
  }
  if ((position ?? mainContainerPosition) === 'before') {
    node.before(mainContainer)
  } else {
    node.after(mainContainer)
  }
  return mainContainer
}
// 메인 iframe 그리기
const _drawMainIframe = (url: string) => {
  const iframeHeight = 638
  var mainIframe = document.createElement('iframe')
  mainIframe.id = mainIframeId
  mainIframe.setAttribute('frameborder', '0')
  mainIframe.style.cssText = `${mainContainerCss} height: ${iframeHeight}px;`
  mainIframe.src = `${host}/${url}/main`
  return mainIframe
}
// 해상도에 따른 뷰 설정
const _setResponsive = (main: IMainStyleLook) => {
  if (main.render) {
    let index = 0
    for (var render of main.render) {
      var element = document.getElementById(`${mainContainerId}${index}`) // 해당 아이디가 존재 하는지 확인
      if (element) {
        var width = window.innerWidth || document.body.clientWidth
        if ((render.minEqualsResponseSize ?? -1) <= width && (render.maxResponseSize ?? 999999) > width) {
          // 현재 설정된 화면 사이즈 안에 들어올 경우
          const nodeList = document.querySelectorAll(`#${mainContainerId}${index} #${mainIframeId}`)
          if (nodeList && nodeList.length < 1 && main.mainIframe) {
            // 리스트 내에 iframe이 없을 경우 추가 해상도 변경으로 인한 중복 호출 방지
            // if (!main.mainIframe.src.includes('?api=no')) {
            //   main.mainIframe.src = `${main.mainIframe.src}?api=no`
            // }
            // 리스트 내에 iframe이 없을 경우 추가
            element.appendChild(main.mainIframe!)
          } else {
            // 리스트 내에 iframe이 있을 경우 block 여부 확인하여 아닐시 block으로 변경
            const style = window.getComputedStyle(element)
            if (style.display !== 'block') {
              element.style.display = 'block'
            }
          }
        } else {
          // 현재 설정된 화면 사이즈 안에 들어오지 않을 경우
          const style = window.getComputedStyle(element)
          if (style.display !== 'none') {
            element.style.display = 'none'
          }
        }
      }
      index++
    }
  }
}
// 화면 변동 시에 이벤트 추가
const _addEventListener = (main: IMainStyleLook) => {
  if (!main.isMainEventListenerAdded) {
    window.addEventListener(
      'resize',
      throttle(() => {
        _setResponsive(main)
      }, 500),
    )
    main.isMainEventListenerAdded = true
  }
  return main
}
