import { thumClssName } from 'src/constants/constants'
import { IAuthframe, IDialogStyleLook, IModalStyleLook, ISetModalNode, QuerySelectorMap } from '../types/stylelook-type'
import { sendIFrameCheckProductNo } from './communicate'
import { drawThumContainer } from './dialog-view'
import { findNodeType } from './utils'
import { isISetModalNode, isISetNode, processModalElement } from './utils_v2'

export const setModalView = (
  modal: IModalStyleLook,
  shopProductList: string[],
  dialog: IDialogStyleLook,
  auth: IAuthframe,
  url: string,
  shopProductMapList: QuerySelectorMap[],
) => {
  for (var render of modal.node ?? []) {
    if (isISetNode(render)) {
      // v1 버전
      if (render.thumRender) {
        const nodeList = document.querySelectorAll(render.thumRender)
        _setModalThumb(nodeList, modal, shopProductList, dialog, url)
      }
      if (render.thumRender && render.targetObserver) {
        const observerList = document.querySelectorAll(render.targetObserver)
        for (var observer of observerList) {
          modal = _setObserverList(observer, render.thumRender, modal, shopProductList, auth) // 부모노드 변경시 이벤트 추가 하기
        }
      }
    } else {
      if (render.version === 'v2') {
        // v2 버전
        shopProductList.forEach((shopProduct) => {
          const findProduct = shopProductMapList.find((product) => product[shopProduct])
          if (findProduct) {
            const renderElements = findProduct[shopProduct]
            if (renderElements) {
              renderElements.map((renderElement) => {
                var style = window.getComputedStyle(renderElement)
                if (style.position !== 'relative') {
                  ;(renderElement as HTMLElement).style.position = 'relative'
                }
                if (!renderElement.querySelector(`.${thumClssName}`)) {
                  // 중복그리기 방지
                  renderElement.appendChild(drawThumContainer(modal.thumContainer, shopProduct, '01', dialog, url))
                }
              })
            }
          }
        })
        // observer 추가
        if (render.isParentObserver ?? true) {
          const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
              const parentElement = mutation.target as Element
              if (isISetModalNode(render)) {
                const childElements = parentElement.querySelectorAll(render.childElementSelectors)
                if (childElements.length === 0) {
                  return null
                }
                childElements.forEach((child) => {
                  processModalElement(child, render as ISetModalNode, shopProductMapList)
                })
                const keys = shopProductMapList.map((item: QuerySelectorMap) => Object.keys(item)).flat()
                const findNewGoodsNo: string[] = []
                for (var shopProduct of keys) {
                  if (shopProductList.includes(shopProduct)) {
                    continue
                  }
                  findNewGoodsNo.push(shopProduct)
                }
                if (auth.authIframe) {
                  sendIFrameCheckProductNo(auth.authIframe, keys)
                }
              }
            })
          })
          const parentElement = document.querySelector(render.parentObserverElementSelectors)
          if (parentElement) {
            observer.observe(parentElement, {
              childList: true,
              subtree: true,
            })
          }
        }
      }
    }
  }
  return modal
}
// 썸네일 셋팅 하는 로직
const _setModalThumb = (
  nodeChildList: NodeListOf<Element>,
  modal: IModalStyleLook,
  shopProductList: string[],
  dialog: IDialogStyleLook,
  url: string,
) => {
  nodeChildList.forEach((node: Element) => {
    const findGoodsNo = _findGoodsNo(node, modal)
    if (findGoodsNo && shopProductList.includes(findGoodsNo)) {
      var style = window.getComputedStyle(node)
      if (style.position !== 'relative') {
        ;(node as HTMLElement).style.position = 'relative'
      }
      if (!node.querySelector(`.${thumClssName}`)) {
        // 중복그리기 방지
        node.appendChild(drawThumContainer(modal.thumContainer, findGoodsNo, '01', dialog, url))
      }
    }
  })
}
const _findGoodsNo = (element: Element, modal: IModalStyleLook) => {
  const resultArr = findNodeType(element, modal)
  return resultArr.length > 0 ? resultArr[0] : null
}
// 부모노드 변경시 데이터 다시 셋팅하기
const _changeParentNode = (
  nodeChildList: NodeListOf<Element>,
  modal: IModalStyleLook,
  shopProductList: string[],
  auth: IAuthframe,
) => {
  const findNewGoodsNo: string[] = []
  nodeChildList.forEach((node: Element) => {
    const findGoodsNo = _findGoodsNo(node, modal)
    if (findGoodsNo && !shopProductList.includes(findGoodsNo)) {
      findNewGoodsNo.push(findGoodsNo)
    }
  })
  if (findNewGoodsNo.length > 0) {
    if (auth && auth.authIframe) {
      sendIFrameCheckProductNo(auth.authIframe, findNewGoodsNo)
    }
  }
}
// 부모노드 변경시 이벤트 추가 하는 로직
const _setObserverList = (
  element: Element,
  node: string,
  modal: IModalStyleLook,
  shopProductList: string[],
  auth: IAuthframe,
) => {
  if (!modal.setObservers?.has(element)) {
    const observer = new MutationObserver((mutations) => {
      const nodeChildList = element.querySelectorAll(node)
      _changeParentNode(nodeChildList, modal, shopProductList, auth)
    })
    observer.observe(element, {
      childList: true,
      attributes: true,
    })
    modal.setObservers.set(element, observer)
  }
  return modal
}
