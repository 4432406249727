import { cartCss, cartDialogCss, cartId, cartIFrameId, host } from '../constants/constants'
import { ICartIFrame } from '../types/stylelook-type'

export const setViewCartModal = (productCode: string, cart: ICartIFrame, url: string) => {
  const cartDialog = document.createElement('div')
  cartDialog.id = cartId
  cartDialog.style.cssText = cartDialogCss

  const cartContainer = document.createElement('div')
  // css
  cartContainer.style.cssText = cartCss
  cartContainer.appendChild(_drawCartIFrame(productCode, url))
  cartDialog.appendChild(cartContainer)
  openCartModal(cartDialog)
}

const _drawCartIFrame = (no: string, url: string) => {
  var cartIFrame = document.createElement('iframe')
  cartIFrame.id = cartIFrameId
  cartIFrame.setAttribute('frameborder', '0')
  cartIFrame.src = `${host}/${url}/cart/${no}`
  cartIFrame.width = '100%'
  cartIFrame.height = '100%'
  return cartIFrame
}

const openCartModal = (cartDialogElement: HTMLDivElement) => {
  document.body.prepend(cartDialogElement)
  document.body.style.overflow = 'hidden'
}
