import {
  defaultMaxResponseSize,
  defaultMinResponseSize,
  detailContainerId,
  detailContainerPosition,
  detailIframeCss,
  detailIframeId,
  host,
  thumClssName,
} from 'src/constants/constants'
import {
  IAuthframe,
  IDetailStyleLook,
  IDialogStyleLook,
  ILookEvent,
  ISetDetailNode,
  renderPositionType,
} from '../types/stylelook-type'
import { sendIFrameCartEvent, sendIFramePdpEvent } from './communicate'
import { drawThumContainer } from './dialog-view'
import { findNodeType, throttle } from './utils'
import { isISetDetailNode, isISetNode, processDetailElement } from './utils_v2'
// 디테일 뷰 다시 그리기
export const reCallDetail = (detail: IDetailStyleLook) => {
  if (detail.detailIframe) {
    detail.detailIframe.src = detail.detailIframe.src.replace('?api=no', '')
  }
}
// 디테일 뷰 셋팅 하기
export const setDetailView = (
  detail: IDetailStyleLook,
  productNo: string[],
  url: string,
  dialog: IDialogStyleLook,
  auth: IAuthframe,
  lookEvent?: ILookEvent,
) => {
  if (detail.render) {
    let resultArr: string[] = []
    if (detail.node && detail.node.length > 0 && isISetNode(detail.node[0])) {
      // v1 버전
      resultArr = findNodeType(document, detail)
    } else if (detail.node && detail.node.length > 0 && isISetDetailNode(detail.node[0])) {
      // v2 버전
      detail.node.forEach((node) => {
        let newStrings = processDetailElement(document, node as ISetDetailNode)
        resultArr = resultArr.concat(newStrings)
      })
    }
    // kpi 때문에 수정
    if (resultArr.length > 0) {
      // kpi 이벤트 추가
      if (lookEvent && lookEvent.isLookEvent) {
        // PdpExposure
        if (auth.authIframe && !productNo.includes(resultArr[0])) {
          // 스타일룩에 없는 상품일때 선 전송!
          sendIFramePdpEvent(auth.authIframe, resultArr[0])
        }
        // cart 이벤트
        if (lookEvent.cartEvent && lookEvent.cartEvent.isCartEvent) {
          lookEvent.cartEvent.cartEventSelector.forEach((selector) => {
            var actionCartElements = document.querySelectorAll(selector)
            actionCartElements.forEach(function (element) {
              element.addEventListener('click', function () {
                if (auth.authIframe) {
                  sendIFrameCartEvent(auth.authIframe, resultArr[0])
                }
              })
            })
          })
        }
      }
    }
    if (resultArr.length > 0 && productNo.includes(resultArr[0])) {
      // 현재 상세 페이지 번호가 있는 경우에만 랜더링 시작
      if (!detail.detailIframe) {
        // iframe 이 없을 경우 생성
        detail.findDetailProductNo = resultArr[0]
        detail.detailIframe = _drawDetailIframe(resultArr[0], url)
      } else if (detail.detailIframe) {
        // iframe 이 있을 경우 주소만 변경
        if (detail.detailIframe.src.includes(`${host}/${url}/detail/${resultArr[0]}`)) {
          // 현재 상세 상품번호와 같을 경우 api no로 call
          if (!detail.detailIframe.src.includes('?api=no') && detail.isLoadDetail) {
            detail.detailIframe.src = `${host}/${url}/detail/${resultArr[0]}?api=no`
          }
        } else {
          detail.isLoadDetail = false
          detail.detailIframe.src = `${host}/${url}/detail/${resultArr[0]}`
        }
      }
      let index = 0
      // 디테일 썸네일 그리기
      if (detail.node && detail.node.length > 0) {
        for (var thumNode of detail.node) {
          if (isISetNode(thumNode)) {
            // v1
            if (thumNode.thumRender) {
              const nodeList = document.querySelectorAll(thumNode.thumRender)
              for (var node of nodeList) {
                var style = window.getComputedStyle(node)
                if (style.position !== 'relative') {
                  ;(node as HTMLElement).style.position = 'relative'
                }
                if (!node.querySelector(`.${thumClssName}`)) {
                  // 중복그리기 방지
                  node.appendChild(drawThumContainer(detail.thumContainer, resultArr[0], '02', dialog, url))
                }
              }
            }
          } else if (isISetDetailNode(thumNode)) {
            // v2
            if (detail.renderThumElementSelectors) {
              detail.renderThumElementSelectors.forEach((render) => {
                const nodeList = document.querySelectorAll(render)
                for (var node of nodeList) {
                  var style = window.getComputedStyle(node)
                  if (style.position !== 'relative') {
                    ;(node as HTMLElement).style.position = 'relative'
                  }
                  if (!node.querySelector(`.${thumClssName}`)) {
                    // 중복그리기 방지
                    node.appendChild(
                      drawThumContainer(
                        detail.thumContainer,
                        resultArr[0],
                        '02',
                        dialog,
                        url,
                        detail.thumClickEventType,
                      ),
                    )
                  }
                }
              })
            }
          }
        }
      }
      // 디테일 화면 그리기
      for (var render of detail.render) {
        var element = document.getElementById(`${detailContainerId}${index}`)
        if (!element && render.renderHref !== undefined && (render.renderHref ?? []).length > 0) {
          // 랜더할 url이 있을 경우
          const currentUrl = window.location.href
          for (var renderHref of render.renderHref) {
            if (currentUrl.includes(renderHref)) {
              const nodeList = document.querySelectorAll(render.renderContainer)
              if (nodeList && nodeList.length > 0) {
                for (var node of nodeList) {
                  _drawDetailContainer(node, detail, index, render.renderPosition ?? detailContainerPosition)
                }
              }
            }
          }
        } else if (!element) {
          // 모두 노출
          var element = document.getElementById(`${detailContainerId}${index}`)
          if (!element) {
            const nodeList = document.querySelectorAll(render.renderContainer)
            if (nodeList && nodeList.length > 0) {
              for (var node of nodeList) {
                _drawDetailContainer(node, detail, index, render.renderPosition ?? detailContainerPosition)
              }
            }
          }
        }
        if (render.minEqualsResponseSize || render.maxResponseSize) {
          var width = window.innerWidth || document.body.clientWidth
          if (
            (render.minEqualsResponseSize ?? defaultMinResponseSize) <= width ||
            (render.maxResponseSize ?? defaultMaxResponseSize) > width
          ) {
            detail = _addEventListener(detail)
          }
          index++
        }
      }
      _setResponsive(detail)
    }
  }
  return detail
}
// 디테일 container 그리기
const _drawDetailContainer = (node: Element, detail: IDetailStyleLook, index: number, position: renderPositionType) => {
  const detailContainer = document.createElement('div')
  detailContainer.id = `${detailContainerId}${index}`
  if (detail.detailElementCustom) {
    detailContainer.classList.add(...(detail.detailElementCustom.classList ?? ['']))
    detailContainer.style.cssText = detail.detailElementCustom.cssList ?? ''
  }
  if ((position ?? detailContainerPosition) === 'before') {
    node.before(detailContainer)
  } else {
    node.after(detailContainer)
  }
  return detailContainer
}
// 디테일 iframe 그리기
const _drawDetailIframe = (no: string, url: string) => {
  const iframeHeight = 400
  var detailIframe = document.createElement('iframe')
  detailIframe.id = detailIframeId
  detailIframe.setAttribute('frameborder', '0')
  detailIframe.style.cssText = `${detailIframeCss} height: ${iframeHeight}px;`
  detailIframe.src = `${host}/${url}/detail/${no}`
  return detailIframe
}
// 해상도에 따른 뷰 설정
const _setResponsive = (detail: IDetailStyleLook) => {
  if (detail.render) {
    let index = 0
    for (var render of detail.render) {
      var element = document.getElementById(`${detailContainerId}${index}`) // 해당 아이디가 존재 하는지 확인
      if (element) {
        var width = window.innerWidth || document.body.clientWidth
        if ((render.minEqualsResponseSize ?? -1) <= width && (render.maxResponseSize ?? 999999) > width) {
          // 현재 설정된 화면 사이즈 안에 들어올 경우
          const nodeList = document.querySelectorAll(`#${detailContainerId}${index} #${detailIframeId}`)
          if (nodeList && nodeList.length < 1 && detail.detailIframe) {
            // 리스트 내에 iframe이 없을 경우 추가
            if (!detail.detailIframe.src.includes('?api=no') && detail.isLoadDetail) {
              detail.detailIframe.src = `${detail.detailIframe.src}?api=no`
            }
            element.appendChild(detail.detailIframe)
            const style = window.getComputedStyle(element)
            if (style.display !== 'block') {
              element.style.display = 'block'
            }
          } else {
            // 리스트 내에 iframe이 있을 경우 block 여부 확인하여 아닐시 block으로 변경
            const style = window.getComputedStyle(element)
            if (style.display !== 'block') {
              element.style.display = 'block'
            }
          }
        } else {
          // 현재 설정된 화면 사이즈 안에 들어오지 않을 경우
          const style = window.getComputedStyle(element)
          if (style.display !== 'none') {
            element.style.display = 'none'
          }
        }
      }
      index++
    }
  }
}

// 화면 변동 시에 이벤트 추가
const _addEventListener = (detail: IDetailStyleLook) => {
  if (!detail.isDetailEventListenerAdded) {
    window.addEventListener(
      'resize',
      throttle(() => {
        _setResponsive(detail)
      }, 500),
    )
    detail.isDetailEventListenerAdded = true
  }
  return detail
}
