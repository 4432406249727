import { authDefaultCss, authIFrameCss, authId, host } from '../constants/constants'
import { IAuthframe, LanguageType } from '../types/stylelook-type'
// 인증 iframe 설정
export const setAuthIFrame = (key: string, lang: LanguageType) => {
  // key 구분
  var isDefaultElement = document.getElementById('defaultIFrameContainer')
  if (isDefaultElement === null) {
    var defaultIFrame = document.createElement('iframe')
    defaultIFrame.setAttribute('frameborder', '0')
    defaultIFrame.style.cssText = authDefaultCss
    defaultIFrame.src = `${host}/${lang}/look?key=${key}`
    return defaultIFrame
  }
  return null
}
// 인증 iframe container
export const drawAuthIFrame = (authIframe: HTMLIFrameElement) => {
  var defaultIFrameContainer = document.createElement('div')
  defaultIFrameContainer.id = authId
  defaultIFrameContainer.style.cssText = authIFrameCss
  defaultIFrameContainer.append(authIframe)
  document.body.prepend(defaultIFrameContainer)
}
// 토큰 문제로 인한 재호출
export const reCallAuth = (auth: IAuthframe) => {
  if (auth.authIframe) {
    auth.authIframe.src = auth.authIframe.src
  }
}
