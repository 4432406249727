import { defaultIframeSendTime, messageFrom } from 'src/constants/constants'

let timeoutId: number | null = null
// 상품 넘버 받는 iframe 통신 함수
export const sendIFrameCheckProductNo = (dom: HTMLIFrameElement, productList: string[]) => {
  console.log('iframe sendIFrameCheckProductNo', productList, dom)
  if (productList.length > 0) {
    dom.contentWindow?.postMessage(
      {
        from: messageFrom,
        type: 'goodsNos',
        data: productList.join(','),
      },
      '*',
    )
  }
}
// 카트 버튼 클릭 iframe 통신 함수
export const sendIFrameCartEvent = (dom: HTMLIFrameElement, productCode?: string) => {
  if (productCode !== '' && timeoutId === null) {
    dom.contentWindow?.postMessage(
      {
        from: messageFrom,
        type: 'cartClick',
        data: productCode ?? '',
      },
      '*',
    )
    timeoutId = window.setTimeout(() => {
      timeoutId = null
    }, defaultIframeSendTime)
  }
}
// 상품 상세페이지 접근 시 iframe 통신 함수
export const sendIFramePdpEvent = (dom: HTMLIFrameElement, productCode?: string) => {
  if (productCode !== '' && timeoutId === null) {
    dom.contentWindow?.postMessage(
      {
        from: messageFrom,
        type: 'PdpExposure',
        data: productCode ?? '',
      },
      '*',
    )
    timeoutId = window.setTimeout(() => {
      timeoutId = null
    }, defaultIframeSendTime)
  }
}
