import { cartId, dialogId } from 'src/constants/constants'
import {
  IModalStyleLook,
  ISetDetailNode,
  ISetModalNode,
  ISetNode,
  IThumContainer,
  IThumContainerV2,
  QuerySelector,
  QuerySelectorMap,
} from '../types/stylelook-type'

/*
디테일, 모달 에 필요한 코드들..
*/
// 모달 닫기
export const closeDialogModal = () => {
  const el = document.querySelector(`#${dialogId}`)
  if (el) {
    el.remove()
  }
  setBodyOverflowAuto()
}
// 장바구니 닫기
export const closeCartModal = () => {
  const el = document.querySelector(`#${cartId}`)
  if (el) {
    el.remove()
  }
  setBodyOverflowAuto()
}
// 확인 후 document body overflow auto 하기
export const setBodyOverflowAuto = () => {
  const dialogEl = document.querySelector(`#${dialogId}`)
  const cartEl = document.querySelector(`#${cartId}`)
  if (!dialogEl && !cartEl) {
    document.body.style.overflow = 'auto'
  }
}
// 디테일, 모달 노드 v1버전 검색
export const isISetNode = (obj: any): obj is ISetNode => {
  return 'thumRender' in obj || 'targetObserver' in obj || 'findNode' in obj
}
// 디테일 노드 v2버전 검색
export const isISetDetailNode = (node: ISetNode | ISetDetailNode): node is ISetDetailNode => {
  return (node as ISetDetailNode).findProductIdSelectors !== undefined
}
// 모달 노드 v2버전 검색
export const isISetModalNode = (node: ISetNode | ISetModalNode): node is ISetModalNode => {
  return (node as ISetModalNode).parentObserverElementSelectors !== undefined
}
// 공통 상품 아이디 찾는 구문
const _renderElementOrProductId = (
  pId: string | null,
  renderElement: Element | undefined,
): string | QuerySelector | null => {
  if (pId) {
    if (renderElement) {
      return {
        key: pId,
        element: renderElement,
      }
    } else {
      return pId
    }
  }
  return null
}
export const processNode = (
  node: ISetDetailNode | ISetModalNode,
  productIdElement: Element,
  renderElement?: Element,
): string | QuerySelector | null => {
  if (node.findProductType === 'element' && node.findProductValue.findType === 'getAttr') {
    // 상품 아이디 찾는 타입이 element 이고, 값이 getAttr 일 경우 attr 에서 검색
    const pId = _getAttrValue(productIdElement, node.findProductValue.findKey)
    return _renderElementOrProductId(pId, renderElement)
  } else if (node.findProductType === 'element' && node.findProductValue.findType === 'getValue') {
    // 상품 아이디 찾는 타입이 element 이고, 값이 getValue 일 경우 value 에서 검색 element 는 input select textarea 만 가능
    const pId = _getValue(productIdElement)
    return _renderElementOrProductId(pId, renderElement)
  } else if (node.findProductType === 'element' && node.findProductValue.findType === 'getHrefParameterCnt') {
    // input value
    const pId = _getHrefParameterCnt(productIdElement, node.findProductValue.findKey)
    return _renderElementOrProductId(pId, renderElement)
  } else if (node.findProductType === 'meta' && node.findProductValue.findType === 'getValue') {
    const pId = _getMetaValue(productIdElement, node.findProductValue.findKey)
    return _renderElementOrProductId(pId, renderElement)
  }
  return null
}
// 디테일 상품 아이디 찾는 구문
export const processDetailElement = (child: Element | Document, node: ISetDetailNode): string[] => {
  const findKey: string[] = []
  let productIdElement: Element | null = null
  if (node.findProductType === 'element') {
    productIdElement = child.querySelector(node.findProductIdSelectors)
  } else if (node.findProductType === 'meta') {
    productIdElement = child.querySelector(`meta[property="${node.findProductIdSelectors}"]`)
  }
  if (productIdElement) {
    const result = processNode(node, productIdElement)
    if (typeof result === 'string') {
      // Add this check
      findKey.push(result as string)
    }
  }
  return findKey
}
// 모달 상품 아이디 찾는 구문
export const processModalElement = (child: Element, node: ISetModalNode, goodsNoMapList: QuerySelectorMap[]) => {
  const renderElement = node.renderElementSelectors !== '' ? child.querySelector(node.renderElementSelectors) : child
  const productIdElement = node.findProductIdSelectors !== '' ? child.querySelector(node.findProductIdSelectors) : child

  if (renderElement && productIdElement) {
    const result = processNode(node, productIdElement, renderElement)
    if (result && typeof result !== 'string' && result.element) {
      let found = false
      for (let map of goodsNoMapList) {
        if (map[result.key]) {
          map[result.key]?.push(result.element)
          found = true
          break
        }
      }
      if (!found) {
        goodsNoMapList.push({
          [result.key]: [result.element],
        })
      }
    }
  }
}
// 모달 자식 노드 처리
const processChildElements = (
  childElements: NodeListOf<Element>,
  node: ISetModalNode,
  goodsNoMapList: QuerySelectorMap[],
) => {
  childElements.forEach((child) => {
    processModalElement(child, node, goodsNoMapList)
  })
}
// 모달 상품id 및 노드 찾기
export const findModalProduct = (findNode: IModalStyleLook): QuerySelectorMap[] => {
  const goodsNoMapList: QuerySelectorMap[] = []
  if (findNode.node && findNode.node.length > 0) {
    findNode.node.forEach((node) => {
      if (isISetModalNode(node)) {
        const parentElements = document.querySelectorAll(node.parentObserverElementSelectors)
        if (!parentElements || parentElements.length === 0) {
          return null
        }
        parentElements.forEach((parentElement) => {
          const childElements = parentElement.querySelectorAll(node.childElementSelectors)
          if (childElements.length === 0) {
            return null
          }
          processChildElements(childElements, node, goodsNoMapList)
        })
      }
    })
  }
  return goodsNoMapList
}
// 모달 썸네일 그리기 v1 찾는 함수
export const isIThumContainer = (obj: any): obj is IThumContainer => {
  return obj.drawType === 'label' || obj.drawType === 'image'
}
// 모달 썸네일 그리기 v2 찾는 함수
export const isIThumContainerV2 = (obj: any): obj is IThumContainerV2 => {
  return !isIThumContainer(obj)
}
// node 검색 함수
// Element 에서 attr 값 가져오기
const _getAttrValue = (element: Element, value: string): string | null => {
  return element.getAttribute(value)
}
// meta 에서 content 값 가져오기
const _getMetaValue = (element: Element, value: string): string | null => {
  return element.getAttribute(value)
}
// Element 에서 value 값 가져오기
const _getValue = (element: Element): string | null => {
  if (
    element instanceof HTMLInputElement ||
    element instanceof HTMLSelectElement ||
    element instanceof HTMLTextAreaElement
  ) {
    return element.value
  }
  return null
}
// Element 에서 href 에서 파라미터 값 가져오기
// 공통 몇번째 url 파라미터에서 찾기
// 자신의 도메인 주소 확인해서 주소 반환하는 함수
const _addDomainToUrl = (url: string): string => {
  try {
    const domain = window.location.origin
    const absoluteUrl = new URL(url, domain)
    return absoluteUrl.toString()
  } catch (e) {
    console.error('error:', e)
    return url // 에러가 발생하면 원래의 url을 반환합니다.
  }
}
// 몇번째 url 에서 파라미터에서 찾기
const _getUrlSearchParamCnt = (url: string, findValue: string): string | null => {
  try {
    const urls = new URL(_addDomainToUrl(url))
    const findValueAsNumber = parseInt(findValue, 10)
    const pathSegments = urls.pathname.split('/') // 경로 세그먼트를 분할합니다.
    return pathSegments[findValueAsNumber]
  } catch (e) {
    console.error('error:', e)
    return null
  }
}
const _getHrefParameterCnt = (element: Element, value: string) => {
  const getUrl = element.getAttribute('href')
  if (!getUrl) return null
  const findValue = _getUrlSearchParamCnt(getUrl, value)
  if (findValue) {
    return findValue
  }
  return null
}
