/************************************************************************************
 * Stylebot Style Look Service
 *  - 스타일봇 스타일매니저 서비스
 *
 * @author StyleBot
 * @version `$version`
 ************************************************************************************/

import {
  defaultLanguage,
  defaultModalHeight,
  defaultModalWidth,
  defaultUrlPath,
  detailContainerId,
  detailIframeId,
  mainContainerId,
  messageFrom,
  version,
} from './constants/constants'
import { drawAuthIFrame, reCallAuth, setAuthIFrame } from './scripts/auth'
import { setViewCartModal } from './scripts/cart-view'
import { sendIFrameCheckProductNo, sendIFramePdpEvent } from './scripts/communicate'
import { reCallDetail, setDetailView } from './scripts/detail-view'
import { reCallDialog } from './scripts/dialog-view'
import { reCallMain, setMainView } from './scripts/main-view'
import { setModalView } from './scripts/modal-view'
import { addArray, findNodeType, isArraysEqual } from './scripts/utils'
import {
  closeCartModal,
  closeDialogModal,
  findModalProduct,
  isISetDetailNode,
  isISetModalNode,
  isISetNode,
  processDetailElement,
} from './scripts/utils_v2'
import {
  FindType,
  IAuthframe,
  IDetailStyleLook,
  IDialogStyleLook,
  ILookEvent,
  IMainStyleLook,
  IModalStyleLook,
  ISetDetailNode,
  IShopProduct,
  IStyleLook,
  LanguageType,
  QuerySelectorMap,
} from './types/stylelook-type'

class StyleLook {
  // 설정 불가
  private static version = version
  private static isMessageListenerAdded = false
  // 파라미터 설정 가능한 값
  key: string // 스타일룩 키 설정
  urlPath?: string // 스타일룩 URL 경로 설정
  language?: LanguageType // 스타일룩 언어 설정
  isShowUI?: boolean // 스타일룩 UI 보이기 설정
  main?: IMainStyleLook // 스타일룩 메인 설정
  detail?: IDetailStyleLook // 스타일룩 상세 설정
  modal?: IModalStyleLook // 스타일룩 모달 설정
  dialog: IDialogStyleLook // 스타일룩 다이얼로그 설정
  lookEvent?: ILookEvent // 스타일룩 이벤트 설정
  // 파라미터 설정 불가능한 값
  auth: IAuthframe // 스타일룩 인증 iframe 설정
  shopProduct: IShopProduct // 스타일룩 상품 설정
  constructor({
    key, // 스타일룩 키 설정
    urlPath = defaultUrlPath, // 스타일룩 URL 경로 설정
    language = defaultLanguage, // 스타일룩 언어 설정
    isShowUI = false, // 스타일룩 UI 보이기 설정
    main = {
      isMainShow: false, // 메인 뷰 보이기 설정
      isMainEventListenerAdded: false, // 리사이즈 이벤트 리스너 추가 했는지 여부
      mainIframe: null, // 메인용 iframe dom
      mainElement: null, // iframe 둘러쌀 div
    }, // 스타일룩 메인 설정
    detail = {
      isDetailShow: false, // 상세 뷰 보이기 설정
      isDetailEventListenerAdded: false, // 리사이즈 이벤트 리스너 추가 했는지 여부
      detailIframe: null, // 상세용 iframe dom
      detailElement: null, // iframe 둘러쌀 div
      node: [], // 노드 설정
      isLoadDetail: false, // 상세 뷰 로드 여부
      findDetailProductNo: '', // 상세 뷰 상품 번호 초기화
    },
    modal = {
      isModalShow: false, // 모달 뷰 보이기 설정
      node: [], // 노드 설정
      setObservers: new WeakMap(), // WeakMap 객체
      modalIframe: null, // 모달용 iframe dom
      modalElement: null, // iframe 둘러쌀 div
    },
    dialog = {
      dialogIframe: null, // 다이얼로그용 iframe dom
      dialogElement: null, // iframe 둘러쌀 div
      dialogWidth: defaultModalWidth, // 다이얼로그 넓이
      dialogHeight: defaultModalHeight, // 다이얼로그 높이
    },
    lookEvent = {
      isLookEvent: true, // 이벤트 여부
      cartEvent: {
        isCartEvent: true, // 카트 이벤트 여부
        cartEventSelector: ['.actionCart'],
        iFrames: {
          cartIFrame: null,
        },
      },
    },
  }: IStyleLook) {
    // 설정 불가능한 값
    this.setupMessageListener()
    // 설정 가능한 값
    this.key = key
    this.urlPath = urlPath
    this.language = language
    this.isShowUI = isShowUI
    this.main = main
    this.detail = detail
    this.modal = modal
    this.dialog = dialog
    this.lookEvent = lookEvent
    // 기본
    this.auth = {
      authIframe: null,
      searchType: '',
      attachOnload: false,
    }
    this.shopProduct = {
      findProductNo: [],
      findProductNoMap: [],
      productNo: [],
      isFirstDomSend: false,
    }
    this.modal.setObservers = new WeakMap()
    this._auth() // 인증 관련 함수
    this._attachEvent() // onload 이벤트 추가
  }
  setupMessageListener() {
    if (!StyleLook.isMessageListenerAdded) {
      // 이벤트 핸들러 로직
      try {
        window.addEventListener('message', async (event) => {
          if (event.data.from === 'styleLook') {
            console.log(`seven event.data.type' ${event.data.type}, 'event.data' ${event.data.data}`)
            if (event.data.type === 'loaded') {
              if (!this.shopProduct.isFirstDomSend) {
                this._find('all')
              }
            } else if (event.data.type === 'goodsNos') {
              // 활성화된 상품 넘버 받기
              const recProdNo = event.data.data.split(',').map((item: string) => item.trim())
              this.shopProduct.findProductNo = []
              // console.log('seven recProdNo', recProdNo)
              // console.log('seven this.shopProduct.productNo', this.shopProduct.productNo)
              if (!isArraysEqual(this.shopProduct.productNo, recProdNo)) {
                addArray(this.shopProduct.productNo, recProdNo)
                this.shopProduct.isFirstDomSend = true
                this.reRender()
              }
              this.auth.searchType = ''
            } else if (event.data.type === 'lookError') {
              if (`${event.data.data}` === '401') {
                // 토큰 에러
                if (this.auth) {
                  reCallAuth(this.auth)
                }
                if (this.main) {
                  reCallMain(this.main)
                }
                if (this.detail) {
                  reCallDetail(this.detail)
                }
                if (this.dialog) {
                  reCallDialog(this.dialog)
                }
                closeCartModal()
              } else if (event.data.data === 'empty') {
                // 스타일 룩에서 에러가 발생 했을때
                console.error('StylebotStyleLook Error', event.data.data)
                if (this.main?.render) {
                  for (let i = 0; i < this.main.render.length; i++) {
                    const element = document.getElementById(`${mainContainerId}${i}`)
                    if (element) {
                      element.remove()
                    }
                  }
                }
                if (this.detail?.render) {
                  for (let i = 0; i < this.detail.render.length; i++) {
                    const element = document.getElementById(`${detailContainerId}${i}`)
                    if (element) {
                      element.remove()
                    }
                  }
                }
                // 열려있는 모달 닫기
                closeDialogModal()
                // 열려 있는 장바구니 닫기
                closeCartModal()
              }
            } else if (event.data.type === 'detailHeight') {
              // 상세 높이 변경
              const iframe = document.getElementById(detailIframeId)
              if (iframe && event.data.data > 0) iframe.style.height = event.data.data + 'px'
            } else if (event.data.type === 'close') {
              // 모달 닫기
              closeDialogModal()
            } else if (event.data.type === 'lookDetailRefresh') {
              if (this.detail) {
                this.detail.isLoadDetail = Boolean(event.data.data)
                if (this.detail.isLoadDetail && this.auth.authIframe && this.detail.findDetailProductNo !== '') {
                  // 상세 있는 경우 Pdp 로드
                  sendIFramePdpEvent(this.auth.authIframe, this.detail.findDetailProductNo)
                }
              }
            } else if (event.data.type === 'cartIFrame') {
              // 장바구니 iframe
              if (event.data.data && event.data.data !== '') {
                this._cartView(event.data.data)
              }
            } else if (event.data.type === 'closeCartIFrame') {
              // 장바구니 닫기
              closeCartModal()
            } else if (event.data.type === 'callCartEvent') {
              // 카트 이벤트 담을 곳!
              if (
                this.lookEvent &&
                this.lookEvent.isLookEvent &&
                this.lookEvent.cartEvent &&
                this.lookEvent.cartEvent.isCartEvent
              ) {
                if (this.lookEvent.cartEvent.callCartEvent) {
                  this.lookEvent.cartEvent.callCartEvent(event.data.data)
                }
              }
            }
          }
        })
      } catch (e) {
        console.error('StylebotStyleLook Error', e)
      }
      StyleLook.isMessageListenerAdded = true
    }
  }
  // 객체 찾기
  _find(type: FindType) {
    this.auth.searchType = type
    if (type === 'detail') {
      if (this.detail) {
        if (this.detail.node && this.detail.node.length > 0 && isISetNode(this.detail.node[0])) {
          // v1
          const resultArr = findNodeType(document, this.detail)
          this.shopProduct.findProductNo = addArray(this.shopProduct.findProductNo, resultArr)
        } else if (this.detail.node && this.detail.node.length > 0 && isISetDetailNode(this.detail.node[0])) {
          // v2
          let resultArr: string[] = []
          this.detail.node.forEach((node) => {
            let newStrings = processDetailElement(document, node as ISetDetailNode)
            resultArr = resultArr.concat(newStrings)
          })
          this.shopProduct.findProductNo = addArray(this.shopProduct.findProductNo, resultArr)
        }
      }
    } else if (type === 'modal') {
      if (this.modal) {
        if (this.modal.node && this.modal.node.length > 0 && isISetNode(this.modal.node[0])) {
          // v1
          const resultArr = findNodeType(document, this.modal)
          this.shopProduct.findProductNo = addArray(this.shopProduct.findProductNo, resultArr)
        } else if (this.modal.node && this.modal.node.length > 0 && isISetModalNode(this.modal.node[0])) {
          // v2
          const resultMapArr = findModalProduct(this.modal)
          const keys = resultMapArr.map((item: QuerySelectorMap) => Object.keys(item)).flat()
          this.shopProduct.findProductNo = addArray(this.shopProduct.findProductNo, keys)
          this.shopProduct.findProductNoMap = resultMapArr
        }
      }
    } else if (type === 'all') {
      if (this.detail) {
        if (this.detail.node && this.detail.node.length > 0 && isISetNode(this.detail.node[0])) {
          // v1
          const resultArr = findNodeType(document, this.detail)
          this.shopProduct.findProductNo = addArray(this.shopProduct.findProductNo, resultArr)
        } else if (this.detail.node && this.detail.node.length > 0 && isISetDetailNode(this.detail.node[0])) {
          // v2
          let resultArr: string[] = []
          this.detail.node.forEach((node) => {
            let newStrings = processDetailElement(document, node as ISetDetailNode)
            resultArr = resultArr.concat(newStrings)
          })
          this.shopProduct.findProductNo = addArray(this.shopProduct.findProductNo, resultArr)
        }
      }
      if (this.modal) {
        if (this.modal.node && this.modal.node.length > 0 && isISetNode(this.modal.node[0])) {
          // v1
          const resultArr = findNodeType(document, this.modal)
          this.shopProduct.findProductNo = addArray(this.shopProduct.findProductNo, resultArr)
        } else if (this.modal.node && this.modal.node.length > 0 && isISetModalNode(this.modal.node[0])) {
          // v2
          const resultMapArr = findModalProduct(this.modal)
          const keys = resultMapArr.map((item: QuerySelectorMap) => Object.keys(item)).flat()
          this.shopProduct.findProductNo = addArray(this.shopProduct.findProductNo, keys)
          this.shopProduct.findProductNoMap = resultMapArr
        }
      } else {
      }
    }
    this._sendIFrameCheckProductNo() // 찾은 detail 상품 번호 체크
  }
  // 인증 관련 함수
  _auth() {
    const _authIframe = setAuthIFrame(this.key, this.language ?? defaultLanguage)
    if (_authIframe !== null) {
      this.auth.authIframe = _authIframe
      drawAuthIFrame(this.auth.authIframe)
    }
  }
  // onload 이벤트 추가
  _attachEvent() {
    if (!this.auth.attachOnload) {
      this.auth.attachOnload = true
      window.addEventListener('load', () => {
        console.log('load')
        if (!this.shopProduct.isFirstDomSend) {
          this._find('all')
        }
        // 인증 성공 이후
      })
    }
  }
  // iframe로 상품 번호 체크
  _sendIFrameCheckProductNo() {
    if (this.auth && this.auth.authIframe) {
      sendIFrameCheckProductNo(this.auth.authIframe, this.shopProduct.findProductNo)
    }
  }
  // 메인 관련 함수
  _main() {
    if (this.main && this.main.isMainShow && this.isShowUI) {
      // 메인 뷰 셋팅 dom 이 있고 보이기 설정이 되어 있을 경우
      console.log('this.main', this.main)
      this.main = setMainView(this.main, `${this.language ?? defaultLanguage}/${this.urlPath ?? defaultUrlPath}`)
    }
  }
  _detailView() {
    if (this.auth.authIframe && this.detail && this.detail.isDetailShow && this.isShowUI) {
      // 상세 뷰 셋팅 dom 이 있고 보이기 설정이 되어 있을 경우
      this.detail = setDetailView(
        this.detail,
        this.shopProduct.productNo,
        `${this.language ?? defaultLanguage}/${this.urlPath ?? defaultUrlPath}`,
        this.dialog,
        this.auth,
        this.lookEvent,
      )
    }
  }
  _modalView() {
    if (this.auth.authIframe && this.modal && this.modal.isModalShow && this.isShowUI) {
      this.modal = setModalView(
        this.modal,
        this.shopProduct.productNo,
        this.dialog,
        this.auth,
        `${this.language ?? defaultLanguage}/${this.urlPath ?? defaultUrlPath}`,
        this.shopProduct.findProductNoMap,
      )
    }
  }
  _cartView(productCode: string) {
    //
    if (this.lookEvent && this.lookEvent.cartEvent && this.lookEvent.cartEvent.iFrames) {
      setViewCartModal(
        productCode,
        this.lookEvent.cartEvent.iFrames,
        `${this.language ?? defaultLanguage}/${this.urlPath ?? defaultUrlPath}`,
      )
    }
  }
  addedCartEvent(data: string) {
    if (this.detail?.detailIframe) {
      this.detail?.detailIframe?.contentWindow?.postMessage(
        {
          from: messageFrom,
          type: 'cartActionResult',
          data: data,
        },
        '*',
      )
    }
    if (this.dialog?.dialogIframe) {
      this.dialog?.dialogIframe?.contentWindow?.postMessage(
        {
          from: messageFrom,
          type: 'cartActionResult',
          data: data,
        },
        '*',
      )
    }
  }
  reRender() {
    console.log('reRender')
    this._main()
    this._detailView()
    this._modalView()
  }
  showUI() {
    this.isShowUI = true
    this.reRender()
  }
  // 객체 찾기
  findAll() {
    this.auth.searchType = 'all'
    if (this.detail) {
      if (this.detail.node && this.detail.node.length > 0 && isISetNode(this.detail.node[0])) {
        // v1
        const resultArr = findNodeType(document, this.detail)
        this.shopProduct.findProductNo = addArray(this.shopProduct.findProductNo, resultArr)
      } else if (this.detail.node && this.detail.node.length > 0 && isISetDetailNode(this.detail.node[0])) {
        // v2
        let resultArr: string[] = []
        this.detail.node.forEach((node) => {
          let newStrings = processDetailElement(document, node as ISetDetailNode)
          resultArr = resultArr.concat(newStrings)
        })
        this.shopProduct.findProductNo = addArray(this.shopProduct.findProductNo, resultArr)
      }
    }
    if (this.modal) {
      if (this.modal.node && this.modal.node.length > 0 && isISetNode(this.modal.node[0])) {
        // v1
        const resultArr = findNodeType(document, this.modal)
        this.shopProduct.findProductNo = addArray(this.shopProduct.findProductNo, resultArr)
      } else if (this.modal.node && this.modal.node.length > 0 && isISetModalNode(this.modal.node[0])) {
        // v2
        const resultMapArr = findModalProduct(this.modal)
        const keys = resultMapArr.map((item: QuerySelectorMap) => Object.keys(item)).flat()
        this.shopProduct.findProductNo = addArray(this.shopProduct.findProductNo, keys)
        this.shopProduct.findProductNoMap = resultMapArr
      }
    }
    this._sendIFrameCheckProductNo() // 찾은 detail 상품 번호 체크
  }
  showVersion() {
    alert(`StyleLook Version: ${StyleLook.version}`)
  }
}
export default StyleLook
