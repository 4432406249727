export const host = 'https://module.dev.stylelook.app'
export const version = 'v0.3.8'

// default stylelook
export const defaultUrlPath = 'look' // 위치 path
export const defaultLanguage = 'ko' // 언어
export const defaultIframeSendTime = 1000 // iframe 중복 전송 방지 시간
// message
export const messageFrom = 'styleLookWebUser' // 메세지 보내는 사람
// auth
export const authDefaultCss = 'width: 0px; height: 0px;' // 인증용 css
export const authId = 'defaultIFrameContainer' // 인증용 iframe id
export const authIFrameCss = 'position: fixed; right: 0; bottom: 0; width: 0px; height: 0px; z-index: -100;' // 인증용 iframe css
// main
export const mainContainerId = 'styleLookMainContainer_' // 메인 컨테이너 id
export const mainContainerCss = 'position: relative; width: 100%;' // 메인 컨테이너 css
export const mainContainerPosition = 'after' // 메인 페이지 그리는 위치
export const mainIframeId = 'styleLookMainIframe' // 메인 iframe id
// detail
export const detailIframeId = 'styleLookDetailIframe' // 디테일 iframe id
export const detailIframeCss = 'position: relative; width: 100%;' // 디테일 iframe css
export const detailContainerId = 'styleLookDetailContainer_' // 디테일 컨테이너 id
export const detailContainerPosition = 'after' // 디테일 페이지 그리는 위치
// thum & dialog
export const thumClssName = 'styleLookThum' // 썸네일 클래스 이름
export const thumOuterCss =
  'position: absolute; top: 5px; right: 5px; cursor: pointer; z-index: 119; justify-content: center; align-items: center; display: flex; line-height: 1; padding: 2px 7px;' // 썸네일 outer css << 확실히 확인 필요
export const thumTextHoverCss =
  'background-color: #1E4737; border-radius: 4px; min-width: 70px; box-sizing: border-box;' // 썸네일 라벨일때 호버시 css
export const thumInnerClassName = 'styleLookThumInner' // 썸네일 inner id
export const thumInnerCss = 'font-size: 11px; color: #fff; font-weight: 400; cursor: pointer;' // 썸네일 inner css
export const thumImageCss = 'width: 100%; height: 100%;' // 썸네일 이미지 css
export const thumImagePath = 'https://media.stylelook.app/images/recommend-icon.png' // 썸네일 이미지 경로
export const thumImageHoverPath = 'https://media.stylelook.app/images/recommend-hover-icon.png' // 썸네일 호버 이미지 경로
export const thumImageArrPath = 'https://media.stylelook.app/images/recommend-closet-icon.png' // 썸네일 배열내 이미지 경로
export const thumImageArrCss = 'width: 16px; height: 16px;' // 썸네일 배열내 이미지 css
export const thumArrCss = 'display: flex; justify-content: center; align-items: center; gap: 4px;' // 썸네일 배열내 css
export const thumArrText = 'font-size: 11px; color: #fff; font-weight: 400;' // 썸네일 배열내 텍스트 css
export const thumTextLabel = 'STYLE LOOK' // 썸네일 라벨
// dialog
export const defaultModalWidth = '540px' // 모달 넓이
export const defaultModalHeight = '750px' // 모달 높이
export const dialogId = 'stylebotStyleLookDialog' // 다이얼로그 id
export const dialogCss =
  'position: fixed; top:0; left:0; right: 0; bottom: 0; background-color: rgba(0, 0, 0, 0.3); z-index: 100002;'
export const dialogInnerId = 'stylebotStyleLookDialogInner'
export const dialogInnerCss =
  'width: 100%; height: 100%; position: relative; display: flex; align-items: center; justify-content: center;'
export const dialogInnerInDivId = 'stylebotStyleLookModalDialog'
export const dialogInnerInDivCss = 'width: 0px; height: 0px; background-color: #fff; border-radius: 8px;'
export const dialogIframeId = 'styleLookModalIframe' // 모달 iframe id
export const dialogIframeCss = 'width: 100%; height: 100%; border-radius: 1rem;' // 모달 iframe css
// responsive
export const defaultMinResponseSize = -1 // 최소 응답 사이즈
export const defaultMaxResponseSize = 999999 // 최대 응답 사이즈
// cart
export const cartId = 'styleBotStyleLookCart' // 카트 id
export const cartDialogCss =
  'position: fixed; top:0; left:0; right: 0; bottom: 0; background-color: rgba(0, 0, 0, 0.3); z-index: 100004;'
export const cartIFrameId = 'styleBotStyleLookCartIFrameId' // 카트 컨테이너 id
export const cartCss = 'width: 100%; height: 100%; position: fixed; z-index: 100005;'
